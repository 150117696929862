import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { SwalService } from "./swal.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private readonly IN_AUTH_TOKEN_KEY = "in-auth-token";
  private readonly CONTENT_TYPE_FORM_URL_ENCODED = "application/x-www-form-urlencoded; charset=UTF-8";
  private readonly CONTENT_TYPE_JSON = "application/json";
  private readonly CONTENT_TYPE_FORM = "multipart/form-data";

  constructor(
    private http: HttpClient,
    private swalService: SwalService,
    public spinner: NgxSpinnerService
  ) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error.error)}`);
    }
    return throwError("Something went wrong. Please try again later.");
  }

  get headers() {
    return {
      [this.IN_AUTH_TOKEN_KEY]: sessionStorage.getItem(this.IN_AUTH_TOKEN_KEY) || "",
      "Content-Type": this.CONTENT_TYPE_FORM_URL_ENCODED,
    };
  }

  get ctoken_headers() {
    return {
      [this.IN_AUTH_TOKEN_KEY]: sessionStorage.getItem(this.IN_AUTH_TOKEN_KEY) || "",
      "Content-Type": this.CONTENT_TYPE_FORM_URL_ENCODED,
      ctoken: "",
    };
  }

  get newheaders() {
    return {
      [this.IN_AUTH_TOKEN_KEY]: sessionStorage.getItem(this.IN_AUTH_TOKEN_KEY) || "",
      "Content-Type": this.CONTENT_TYPE_JSON,
    };
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": this.CONTENT_TYPE_JSON,
      [this.IN_AUTH_TOKEN_KEY]: sessionStorage.getItem(this.IN_AUTH_TOKEN_KEY) || "",
    });
  }

  private getHeadersWithToken(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": this.CONTENT_TYPE_JSON,
      Authorization: "Bearer " + localStorage.getItem("token"),
    });
  }

  get(url: string): Observable<any> {
    return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError));
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError));
  }

  httpGetMethod(url: string, errBypass?: boolean, envUrl?: string, headers?, params?): Observable<any> {
    if(envUrl !== undefined && envUrl !== '') {
      url = `${envUrl}${url}`;
    } else {
      envUrl = environment.url;
      url = `${envUrl}${url}`;
    }
    
    let options: any = params
      ? {
          headers: new HttpHeaders(headers ? headers : this.headers),
          params,
          observe: 'body'
        }
      : {
          headers: new HttpHeaders(headers ? headers : this.headers),
          observe: "body",
        };
    
    return this.http.get(url, options).pipe(
      tap((response) => {
        let data: any = response;
        if (data[`status`] == -114 || data[`status`] == -106) {
          this.handleSessionExpired();
        } else if (data.status == 0) {
          return data;
        } else if (
          errBypass != true &&
          [-102, -103, -107, -104, -105].includes(data.status)
        ) {
          this.handleApiError(data[`txt`]);
          return [];
        }
      }),
      catchError((err) => {
        this.handleSwalError(err?.message);
        return [];
      })
    );
  }

  httpPostMethods(
    url: string,
    body: any,
    errBypass?: any,
    headers?: any,
    envUrl?: string,
    params?: any
  ): Observable<any> {
    if (envUrl !== undefined && envUrl !== "") {
      url = `${envUrl}${url}`;
    } else {
      envUrl = environment.url;
      url = `${envUrl}${url}`;
    }

    let options: any = params
      ? {
          headers: new HttpHeaders(headers ? headers : this.newheaders),
          params,
          observe: 'response'
        }
      : {
          headers: new HttpHeaders(headers ? headers : this.newheaders),
          observe: "body",
        };
    return this.http.post(url, body, options).pipe(
      tap((response) => {
        let data: any = response;
        if (data[`status`] == -114 || data[`status`] == -106) {
          this.handleSessionExpired();
        } else if (data.status == 0) {
          return data;
        } else if (
          errBypass != true &&
          [-102, -103, -107, -104].includes(data.status)
        ) {
          this.handleApiError(data[`txt`]);
          return [];
        }
      }),
      catchError((err) => {
        this.handleSwalError(err?.message);
        return [];
      })
    );
  }
 

  private handleSessionExpired() {
    this.swalService.error("Session Expired");
    sessionStorage.clear();
    window.location.href = document.location.origin + "/ipdsv2/login";
  }

  private handleApiError(errorMessage: string) {
    this.swalService.error(errorMessage);
  }

  private handleSwalError(errorMessage: string) {
    this.swalService.error(errorMessage);
  }
}

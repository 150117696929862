import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SvgIconsComponent } from "./directives/svg-icons/svg-icons.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MytransactionComponent } from "./dashboard/mytransaction/mytransaction.component";
import { ProductPanelComponent } from "./dashboard/product-panel/product-panel.component";

import { NewProducerComponent } from "./dashboard/new-producer/new-producer.component";
import { NewSubProducerComponent } from "./dashboard/new-sub-producer/new-sub-producer.component";
import { UserManagementComponent } from "./dashboard/user-management/user-management.component";
import { BulkUploadComponent } from "./dashboard/bulk-upload/bulk-upload.component";
import { SearchComponent } from "./dashboard/search/search.component";
import { UserProfileComponent } from "./dashboard/user-profile/user-profile.component";
import { PageNotfoundComponent } from "./common-component/page-notfound/page-notfound.component";
import { CanActivateGuardService } from "./services/can-activate-guard.service";
import { NotificationComponent } from "./dashboard/notification/notification.component";
import { ComboProductsComponent } from "./dashboard/combo-products/combo-products.component";
import { AlleachapplicationComponent } from "./alleachapplication/alleachapplication/alleachapplication.component";
import { AdditionalDiscountDetailsComponent } from "./additional-discount-details/additional-discount-details.component";
import { SearchNsmComponent } from "./search-nsm/search-nsm.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "svg-icons", component: SvgIconsComponent },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "mytransactions",
    component: MytransactionComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "panel",
    component: ProductPanelComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "createproducer",
    component: NewProducerComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "createsubproducer",
    component: NewSubProducerComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "user-mgmt",
    component: UserManagementComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "bulkUpload",
    component: BulkUploadComponent,
    canActivate: [CanActivateGuardService],
  },
  // { path: 'bulk-upload', component: BulkUploadPanelComponent,canActivate:[CanActivateGuardService] },
  {
    path: "search",
    component: SearchComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "userProfile",
    component: UserProfileComponent,
    canActivate: [CanActivateGuardService],
  },
  { path: "notification", component: NotificationComponent },
  {
    path: "comboproducts",
    component: ComboProductsComponent,
    canActivate: [CanActivateGuardService],
  },
  { path: "pageNotFound", component: PageNotfoundComponent },
  {
    path: "allapplication",
    loadChildren: () =>
      import("./new-dashboard/new-dashboard.module").then(
        (m) => m.NewDashboardModule
      ),
    canActivate: [CanActivateGuardService],
  },
  {
    path: "alleachapplication/:id",
    loadChildren: () => import("./alleachapplication/alleachapplication.module").then((m)=> m.AlleachapplicationModule),
    canActivate: [CanActivateGuardService],
  },
  {
    path: "lockingscreen",
    loadChildren: () =>
      import("./locking-screen/locking-screen.module").then(
        (m) => m.LockingScreenModule
      ),
    canActivate: [CanActivateGuardService],
  },
  {
    path: "additional-discount-details",
    component: AdditionalDiscountDetailsComponent,
    canActivate: [CanActivateGuardService],
  },
  {
    path: "search-nsm",
    component: SearchNsmComponent,
    canActivate: [CanActivateGuardService],
  },
  { path: "**", redirectTo: "/pageNotFound" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseServiceService } from './services/base-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { version } from '../../package.json';
import { PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { ScriptLoaderService } from './services/script-loader/script-loader.service';
import {DEFAULT_INTERRUPTSOURCES, Idle, IdleExpiry} from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  title = 'tataaig-user-management';
  update: boolean = false;
  timeoutSubscription: Subscription;
  pageNotFound: boolean = false;
  isCtoken = false;

  constructor(public service: BaseServiceService, public scriptLoader: ScriptLoaderService, private translate: TranslateService, public router: Router, public location: PlatformLocation, updates: SwUpdate, private idle:Idle, private keepalive:Keepalive) {
    translate.addLangs(['en', 'hi']);
    translate.setDefaultLang('en');
   
    //Login-Timeout

    idle.setIdle(900);
    idle.setTimeout(300);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.timeoutSubscription = idle.onTimeout.subscribe(() => {
      this.logout();
      Swal.fire(this.translate.instant('staticTs.sessionExpired'),this.translate.instant('staticTs.loginAgain'),this.translate.instant('staticTs.error'));
    });
    router.events.subscribe((val) => {
      if(sessionStorage.getItem('in-auth-token') != '' && sessionStorage.getItem('in-auth-token') != undefined)
          idle.watch();
      else
        idle.stop();
      
      if (val instanceof NavigationEnd ) {
        this.pageNotFound = false;
        if (val.urlAfterRedirects === '/pageNotFound') {
          this.pageNotFound = true
        }
        setTimeout(() => {
          window.dispatchEvent(new HashChangeEvent("hashchange"));
        }, 1000);
      }

    });
  }

  ngOnInit() {
    this.scriptLoader.loadCommonElements(
      environment.commonPath,
      environment.commonCssPath,
      "login-common.css"
    );

    var actual_url = window.location.href;
    var hostname = window.location.hostname;
    actual_url.includes("ctoken")
    ? (this.isCtoken = true)
    : (this.isCtoken = false);

    if (hostname == 'uatipds2.cloware.in' || hostname == 'uatipds3.cloware.in') {
      actual_url = actual_url.replace(hostname, "uatipds1.cloware.in");
      window.location.href = actual_url;
    } else if (hostname == 'ipds2.cloware.in' || hostname == 'ipds3.cloware.in') {
      actual_url = actual_url.replace(hostname, "ipds1.cloware.in");
      window.location.href = actual_url;
    } else if (hostname == 'ipdsbeta2.tataaig.com' || hostname == 'ipdsbeta3.tataaig.com') {
      actual_url = actual_url.replace(hostname, "ipdsbeta1.tataaig.com");
      window.location.href = actual_url;
    } else if (hostname == 'sellmedi.tataaig.com' || hostname == 'sellmotor.tataaig.com') {
      actual_url = actual_url.replace(hostname, "sellonline.tataaig.com");
      window.location.href = actual_url;
    }

    
    sessionStorage.removeItem('quote_id');
    sessionStorage.removeItem('customer_id');
    sessionStorage.removeItem('customer_detail');
    sessionStorage.removeItem('proposal_clone_data');
    sessionStorage.removeItem('quote_clone_data');                             //clear customer diary session
		sessionStorage.removeItem('backFromCustomerdiary');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.timeoutSubscription.unsubscribe();
  }
  trans() {
    this.router.navigateByUrl('/mytransactions');
  }
  changeLanguage(language) {
    this.translate.use(language.detail);
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  logout(){
    sessionStorage.clear();
    const { origin } = window.location;
    const href = origin.indexOf("localhost") === -1 ? "/ipdsv2/login" : "/";
    if (href == "/") {
      window.location.href = `http://localhost:4201/#/login`;
    } else {
      window.location.href = `${origin}${href}#login`;
    }
  }
}

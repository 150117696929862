import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SvgIconsComponent } from './directives/svg-icons/svg-icons.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
// import { TagicStylesModule } from 'tagic-styles';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { CountdownModule } from 'ngx-countdown';
import { MytransactionComponent } from './dashboard/mytransaction/mytransaction.component';
import { ProductPanelComponent } from './dashboard/product-panel/product-panel.component';


import { NewProducerComponent } from './dashboard/new-producer/new-producer.component';
import { NewSubProducerComponent } from './dashboard/new-sub-producer/new-sub-producer.component';
import { UserManagementComponent } from './dashboard/user-management/user-management.component';
import { BulkUploadPanelComponent } from './dashboard/bulk-upload-panel/bulk-upload-panel.component';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RecaptchaModule } from 'ng-recaptcha';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchComponent } from './dashboard/search/search.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LeftmenuModule } from 'leftmenu';
import { HeaderModule } from 'header';
import { Interceptor } from './services/http-interceptor';
import { InrcurrencyPipe } from './inrcurrency.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { Globals } from './globals';
import { ErrorMessagePipe } from './error-message.pipe';
import { UserProfileComponent } from './dashboard/user-profile/user-profile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TrimDirective } from './directives/trim.directive.';
import { DatePipeFormat } from './DatePipeFormat.pipe';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CanActivateGuardService } from './services/can-activate-guard.service';
import { ToastrModule } from 'ngx-toastr';
import { MatTableModule } from "@angular/material/table";
import { MatListModule } from "@angular/material/list";
import { NotificationComponent } from './dashboard/notification/notification.component';
import { ComboProductsComponent } from './dashboard/combo-products/combo-products.component';
import { FeaturesAreaComponent } from './dashboard/mytransaction/features-area/features-area.component';

import { MatCheckboxModule } from "@angular/material/checkbox";
import { BulkUploadComponent } from './dashboard/bulk-upload/bulk-upload.component';
import { AdditionalDiscountDetailsComponent } from './additional-discount-details/additional-discount-details.component';
import { SearchNsmComponent } from './search-nsm/search-nsm.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SvgIconsComponent,
    DashboardComponent,
    MytransactionComponent,
    ProductPanelComponent,
    NewProducerComponent,
    NewSubProducerComponent,
    UserManagementComponent,
    BulkUploadPanelComponent,
    SearchComponent,
    UserProfileComponent,
    InrcurrencyPipe,
    ErrorMessagePipe,
    TrimDirective,
    DatePipeFormat,
    NotificationComponent,
    ComboProductsComponent,
    FeaturesAreaComponent,
    BulkUploadComponent,
    AdditionalDiscountDetailsComponent,
    SearchNsmComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    NgxMaskModule.forRoot(),
    DashboardModule,
    HttpClientModule,
    CountdownModule,
    AngularMultiSelectModule,
    NgbModule,
    Ng2SearchPipeModule,
    RecaptchaModule,
    BsDropdownModule.forRoot(),
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDropzoneModule,
    ToastrModule.forRoot({
      extendedTimeOut: 10000, // optional
      timeOut: 10000, // optional
      progressBar: true, // need to do
      closeButton: true // If we need close button
    }),
    LeftmenuModule,
    ReactiveFormsModule,
    HeaderModule,
    NgxPaginationModule,
    MatTableModule,
    MatListModule,
    MatCheckboxModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [CookieService, { provide: LocationStrategy, useClass: HashLocationStrategy },
    CanActivateGuardService, {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true,
  }, Globals],
  bootstrap: [AppComponent]
})
export class AppModule {

}

export const Constant = [
  "/v1/profile",
  //'/v1/transact/getrole_name',
  //'/v1/transact/all_active_lob'
];
export const LIST2APIFIELDS =
  "quote.data.agent_first_name,stage,product_id,policy_no,proposal.data_referral_crm_no,proposal.data.basic_tp,data.search.nstp_status,data.search.member_count,data.search.product_name,data.search.selected_new_product_name,data.search.coverage_period,data.search.selected_zone,data.search.producer_name,data.search.channel,data.search.location,data.search.policy_start_date,data.search.sub_product,data.search.coverage_period,data.search.selected_zone,data.search.producer_name,data.search.channel,data.search.premium,data.search.premium_valuewithoutcomma,data.search.customer_email,data.search.inspection_ticket_no,data.search.departure_date,data.search.return_date_single,data.search.proposer_pan,data.search.regno,data.search.proposer_partno,data.search.triptype,data.search.selected_product,data.search.selected_plan,data.search.business_type,data.search.varient,data.search.tenure,data.search.plan_type,data.search.member_is_employee,data.search.policy_plan_name,data.search.bundle_type,data.search.customer_type,data.search.insured_declared_value,data.search.insured_declared_value_valuewithoutcomma,data.search.proposer_title,data.search.customer_mobileno,data.search.customer_name,data.search.customer_email,pdf_date," +
  "quote.data.sub_transaction_type,status,canceled,policy_id,quote.quote_date,policy_start_date_dt,product_group_id,quote_no,quote_id,cust_fld_1,proposal_no,proposal_id,data.certificate_seq_no,assigned_to,policy_end_date,data.search.sme_flag,broker_code,quote.data.transaction_type,data.search.passport_1,data.search.passport_2,data.search.passport_3,data.search.passport_4,data.search.passport_5,data.search.quote_id,quote.data.query_option_yn,data.search.product_id,quote.data.tag,buttonClick,cart_id,data.renewal_quote_id";
export const REMOVES_LEADING_AND_TRAILING_SPECIAL_CHARACTER_REGEX =
  /(^| +)[!-\/:-@\[-`\{-~]*([^ ]*?)[!-\/:-@\[-`\{-~]*(?=\s|$)/gi;

export const TERMS_AND_CONDITION_CATEGORIES = [
  "agent individual",
  "internal posp",
];

export const API_END_POINTS = {
  syncProducerBalance: "transact/syncProducerBalance",
  checkEmailAlias: "transact/checkEmail_alias",
  authOpt: "auth/otp",
  profile: "profile",
  allProducerChannel: "transact/all_producer_channel",
  masterLobMap: "master/product_groups/lob_map/",
  allActiveLob: "transact/all_active_lob",
  masterProductIcon: "master/product_groups/product_icons",
  encryptProposal: "commonmasterdata/encryptproposal?",
  lobGroupMapDropdown: "master/product_groups/lob_group_map_dropdown/",
  lobGroupMapPopupDropdown:
    "master/product_groups/lob_group_map_popup_dropdown/",
  umbroker: "transact/um_producer",
  deactiveStatus: "transact/deactivatedeouser",
  activateStatus: "transact/activatedeouser",
  saverevalexcel: "transact/revalDeoExcel",
  bulkDeuUserCreation: "transact/insertsampledataentrybyexcelbatch",
  getParentPayMode: "transact/getparentPaymentMode",
  getAutoloadData: "transact/getParentextraDetail",
  masterRecord: "master/",
  dataEntryUserAdminSingle: "transact/dataEntryUser/",
  getLobProduct: "transact/lob_based_products",
  singleproducer: "transact/singleproducer/",
  dataEntryUserAdminInsert: "transact/dataEntryInsert",
  dataEntryUser: "transact/dataEntryUser/",
  getProductBasedPlan: "transact/product_based_plan",
  getPlanBasedZone: "transact/plan_based_zone",
  getDealerDetails: "generic_api/dealer_details",
  getLocationName: "transact/locationData?location_code=",
  roleName: "transact/getrole_name",
  roleDetails: "transact/getrole_details",
  productPlanZoneMaster: "transact/product_plan_zone_master",
  subTransactionType: "nonrater_products/subtransact_type?lob=",
  viewTransactionList: "dashboard_search/view_transationList?",
  viewTransactionLob: "dashboard_search/view_transaction_lob",
  newViewTransactionLob: "/dashboard_search/view_transaction_list_lob",
  productGroupLookup: "transact/product_groups_lookup",
  producerLocking: "transact/producerlocking",
  brokerLocationMappedProducer: "transact/broker_location_mapped_producers",
  postCheckUserValid: "transact/post_checkUservalid",
  getUserDetails: "transact/getUserDetail",
  checkBrokerValid: "transact/checkbrokervalid",
  otp: "auth/otp",
  auth: "auth",
  getDeoDomain: "transact/getdeodomain",
  getDeoDetails: "transact/getdeo_details",
  getNewsRole: "add_newsfeed_details/newsrole?emailid=",
  getNews: "add_newsfeed_details/getnews?",
  producerValidity: "transact/checkbrokervalid",
  checkUser: "transact/getUserDetail",
  insertUser: "transact/insUwMas",
  updateUser: "transact/update_all",
  makeHistory: "transact/maintainHistory",
  brokerInsert: "transact/brokerInsert",
  userList: "transact/allinsUwMas",
  userSingle: "transact/singleuser/",
  brokerList: "transact/producerData",
  navigateToMva:
    "plugin_hdb_product_validation/redirectHdbToMvaLink?userMailId=",
  brokerUserCreationInsert: "transact/broker_user_creation",
  getAutoloadUserData: "transact/csm_based_lob_loc_products",
  dataEntryUserAdminList: "transact/dataEntryUsers",
  getRoles: "transact/all_active_roles",
  getProducts: "transact/all_active_products",
  productPanel: "master/product_groups/panel",
  getactiveBrokers: "transact/all_active_brokers",
  insertProduct: "transact/productInsert",
  insertLob: "transact/lobInsert",
  insertRole: "transact/roleInsert",
  getLobLevel: "transact/lob_level",
  getProducerLocMapped: "transact/gc_location",
  getParentProduct: "transact/parent_products",
  getLocationCode: "transact/get_location_code",
  notification: "um_notification/post_primaryNotifications",
  getAllLob: "transact/profileLOB/",
  checkDeoBrokerValid: "transact/checkdeobrokervalid",
  updateDeoProLoc: "transact/update_deoproloc",
  checkDomainEmail: "transact/checkEmail_alias",
  getOtpAlaisGenerate: "auth/otp?",
  getRenewalNavigation: "master/product_groups/search_renewal_navigation/",
  getOtpAndMailCheckAuth: "auth?",
  getParentExtraDetail: "transact/getParentextraDetail",
  lobGroupMap: "master/product_groups/lob_group_map/",
  takeOver: "quote/claim/",
  locationRecord: "transact/getUserProducerLocation",
  loginProducerCodeCheck: "transact/getProducerCodeData",
  loginProducerEmailCheck: "auth/impersonate/",
  lobIcons: "master/product_groups/icon",
  lobSideIcons: "master/product_groups/icon_side_bar",
  searchNavigation: "master/product_groups/search_navigation/",
  getLocation: "transact/get_location_detail?name=",
  channelMapper: "master/channel_mapper/?name=",
  bancaChannel: "master_data/fetchchannel",
  getProdcutBasedPlan: "transact/getProductBasePlan",
  getPlanBaseZone: "transact/getPlanBaseZone",
  productSearchNavigation: "master/product_groups/product_search_navigation/",
  getCsmLocationBroker: "transact/csm_location_broker",
  getEndorsementUrl: "spa_redirect/get_spa?resource=",
  getMyRequestUrl: "spa_redirect/get_spa?resource=MY_REQUEST&role=",
  getmyRequestSPAUrl: "spa_redirect/get_spa?resource=UNIVERSAL&role=",
  getRenewalRedirect: "master/product_groups/renewal/lob",
  underWriterDeviation: "motornstpservice/ssoTicket",
  getRenewalProduct: "motornstpservice/getrenwalproduct",
  productHoverIcon: "master/product_groups/product_hover_icons",
  cancelPolicy: "btapolicycancel/policycancel?policy_id=",
  quickLinks: "master/product_groups/quick_links/motor",
  roleSearchCriteria: "transact/role_search_criteria",
  landingPage: "master/product_groups/landing_page/",
  checkcTokenBrokerValid: "transact/ctokencheckbrokervalid",
  policyService: "policy?policy_id=",
  getDetailsByQuoteId: "policy?quote_id=",
  setPolicyMeta: "policy/meta/",
  getPolicyStatus: "ltapolicy_cancellation/getpolicystatus?policy_no=",
  getBancaDirectProducer: "master/banca_direct_producer?name=",
  getSolIdForProducer: "commonmasterdata/getSolidForProducer?",
  getSPMaster: "commonmasterdata/spdata?",
  boltRedirection: "generic_api/bolt_redirection",
  gotgMasterPolicyList: "GOTG/policy_list?producer_code=",
  personalAccidentPolicyList:
    "GroupAccidentGuard/master_policies?producer_code=",
  getCVRenewalShow: "master/cvrenewal/renewalproducer/",
  getLockedProposalList: "generic_api/locked_proposal_list",
  getDomainName: "ipds_payment/domain_name",
  downloadPayInSlip: "ipds_payment/payinslip_pdf?",
  validateEndorsementUser: "nonrater_products/userallowed?producer_code=",
  encryption: "ipds_payment/encryption_service",
  feedbackSurvey: "feedback_survey/insertfeedbacksurvey",
  feedbackSurveyStatus: "feedback_survey/checkfeedback_survey",
  termsConditionStatus: "generic_api/terms_status",
  termsConditionInsert: "generic_api/terms_insert",
  productCount: "transact/Product_count?email=",
  nonSme:"spa_redirect/encrypt?text=",
  ecRenewalUserAllowed: "ec_renewal/EcrUserallowed?producer_code=",
  fetchHistoryLogs: "nsm_discount/fetchHistory?quote_id=",
  fetchDiscountRequest: "nsm_discount/fetchDiscountRequest?quote_id=",
  fetchActionInfo: "nsm_discount/fetchActionInfo?initiator_type=",
  statusHistory: "nsm_discount/statusHistoryInfo?quote_id=",
  documentAttach: "document/attach",
  referTo: "nsm_discount/immediateReportee?",
  policy: "policy?quote_id=",
  search_nsm: "nsm_discount/nsmDiscountList",
  nsmMailService: "nsm_discount/nsmMailService",
  nsmCtoken:"nsm_discount/ctokenData",
  higherReportee: "nsm_discount/higherReportee?",
  customer360Url: 'generic_api/spa_customer_integration',
  validateLink: "nsm_discount/getCurrentAssignee?quote_id="
};

export const CUSTOMER_LINK_MOTOR_PRODUCTS = [
  "Private Car",
  "Two Wheeler",
  "SACPA",
  "Commercial Vehicle",
];

export const EDI = {
  AIGC: [
    { id: 0, name: "Sookshma And Laghu", value: "Sookshma&Laghu" },
    { id: 1, name: "Employees Compensation", value: "Employee Compensation" },
  ],
  TRAVEL: [{ id: 0, name: "GOTG", value: "gotg" }, { id: 0, name: "GDTA", value: "gdtg"}],
};

export const AIGC_RENEWALS = [
  {
    id: 0,
    name: "Sookshma And Laghu",
    value: "Sookshma And Laghu",
    imgName: "Director_Officer_Liability.svg",
  }
];

export const TEMPORARY_PRODUCTS = [
  {
    name: "TW MVA",
    lob: "warrantyproducts",
    icon_name: "2Wheeler.svg",
  },
  {
    name: "Group Criticare 360",
    lob: "health",
    icon_name: "GMC_Non_EE.svg",
  }
  // {
  //   name: "Leisure Travel Accident 2.0",
  //   lob: "lta",
  //   icon_name: "LTA_svg.svg",
  // },
];

export const ALLEACH_URLS = {
  "sookshma&LaghuRenewal": "/ipdsv2/qms/aigcBre/#/aigcBre/renewal/renewal-list",
  employeeCompensationRenewal: "/ipdsv2/qms/ECR/#/ecr/renewal/renewal-list",
  "sookshma&LaghuEDI": "/ipdsv2/qms/aigcBre/#/aigcBre/bulk/bulk-upload",
  employeeCompensationEDI: "/ipdsv2/qms/ECR/#/ecr/bulk/bulk-upload",
  motorEDI: "/ipdsv2/motor/privatecar/#/edi",
  ltaEDI: "/ipdsv2/lta/#/edi",
  travelBTA: "/ipdsv2/bta/#/bulkcancel",
  predefinedList: "/ipdsv2/motor/twowheeler/#/twowheeler/predefinedList",
  ltaExceptionMaster: "/ipdsv2/lta/#/exception",
  uwCrm: "/ipdsv2/motor/uwcrm/#/uwcrm",
  gmc360: "/ipdsv2/health/gmc-360/#/gmc360/planDetails?type=Y3JpdGljYXJl"
};
export const ALLEACH_CONST = {
  locked: "LOCKED",
  errCode: "Errorcode :",
  deo: "data entry operator",
  broker: "broker",
  health: "health",
  personalAccident: "personalaccident",
  safari: "safari",
  csm: "csm",
  marine: "marinecargo",
  marineEndorsement: "MARINE_ENDORSEMENT",
  createEndorsement: "CREATE_ENDORSEMENT",
  mobilergx: /^[6-9][0-9]{9}$/,
  eeEndorsement: "EMPLOYER_EMPLOYEE_ENDORSEMENT",
  custSpp: "customersupport",
  custRet: "customersupportretention",
  uw: "underwriter",
  others: "others",
  aigc: "aigcombined",
  motor: "motor",
  prod: "PRODUCTION",
  beta: "BETA",
  lta: "lta",
  travel: "travel",
  sook_laghu: "Sookshma&Laghu",
  empComp: "Employee Compensation",
  sookLaghu: "Sookshma And Laghu",
  empCompRater: "Employees Compensation Rater",
};

export const SEARCH_CONST = {
  payment: "payment",
};

export const SEARCH_NSM = {
  headerList: [
    "quoteId",
    "product",
    "clientName",
    "BaseQuotePremium",
    "status",
    "action",
    "quicklinks"
  ],
};

// export const SUBTRANSACTIONTYPES = [
//     { id: 1, value: "Renewal with Payment" },
//     { id: 2, value: "Renewal with Balance Transfer" },
//     { id: 3, value: "Balance Transfer Endorsement" },
//     { id: 4, value: "Refund" },
//     { id: 5, value: "Inter-Voyage Balance Transfer (IBT)" },
//     { id: 6, value: "Others" },
// ];

// export const AIGCSUBTRANSACTIONTYPES = [
//     {id :1, value: "Change of Location_UW"},
//     {id :2, value: "Transfer of Assets_OPS"},
//     {id :3, value: "Change in SI Limit_OPS"},
//     {id :4, value: "Addition of Location_UW"},
//     {id :5, value: "Addition of Coverage/Section_OPS"},
//     {id :6, value: "Sum Insured Reinstatement_OPS"},
//     {id :7, value: "Others_UW"},
//     {id :8, value: "Addition of hypothecator/financier_OPS"},
//     {id :9, value: "Deletion of hypothecator/financier_OPS"},
//     {id :10, value: "Change in insured name_OPS"},
//     {id :11, value: "Change in communication address_OPS"},
//     {id :12, value: "Change in GST_OPS"},
//     {id :13, value: "Change of risk location_UW"},
//     {id :14, value: "Addition of clauses/conditions_UW"},
//     {id :15, value: "Deletion of clauses/conditions_UW"},
//     {id :16, value: "Change of Add on SI_UW"},
//     {id :17, value: "Change in asset details_UW"},
//     {id :18, value: "Change in deductibles_UW"},
//     {id :19, value: "Others_UW"},
//     {id :20, value: "Change in nature of business  - Trade category change_UW"},
//     {id :21, value: "Addition/Deletion of Insured workers_UW"},
//     {id :22, value: "Increase/Decrease Of Wages_UW"},
//     {id :23, value: "Extension of policy period_UW"},
//     {id :24, value: "Cancellation of policy in flat or pro-rata basis_UW"},
//     {id :25, value: "Addition/deletion add-ons cover - Mid Term_UW"},
//     {id :26, value: "Premium adjustment endorsement post policy expiry_UW"},
//     {id :27, value: "Change in loading and Discount endo._UW"},
//     {id :28, value: "Additional Insured Endorsements_UW"},
//     {id :29, value: "Change in Occupancy Description (Nature of work)_UW"},
// ]

export const REGEX = {
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  validateEmail:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
};

export const PRODUCT_ID = {
  sookshmaAndLaghu: "M200000000034",
  employeeCompensation: "M200000000040",
};

export const COMMON_WORDS = {
  true: "true",
  false: "false",
  stop: "stop",
  allApplication: "/allapplication",
  employee: "employee",
  direct: "direct",
  broker: "broker",
  agent: "agent",
  inAuthToken: "in-auth-token",
  email: "email",
  usertype: "usertype",
  producer: "producer",
  location: "location",
  role: "role",
  firstName: "first_name",
  lastLogin: "last_login",
  producerCode: "producer_code",
  locationList: "locationlist",
  brokerList: "brokerlist",
  producerType: "producer_type",
  profileRole: "profile_role",
  parentCode: "parent_code",
  channel: "channel",
  serverTimeout: "Server Timeout!",
  success: "success"
};

export const URLS = {
  discrepencyReviewTransaction:
    "/ipdsv2/discrepancy/#/discrepency/review-transaction?discrepency_id=",
};

export const ERROR_MESSAGES = {
  serverTimeout: "Server Timeout!",
  userExpired: "User ID Expired",
  captchaRequired: "Captcha Required",
  inactiveUser: "Inactive User",
  userIdNotCreated: "User ID is Not Created",
  enterCorrectUserCreds: "Please Enter Correct User Credentials",
  invalidPartnerCode: "Invalid Partner Code",
  invalidEmail: "Invalid Email!",
  invalidParent: "Invalid Parent!",
  enterCorrectCreds: "Please enter correct user credentials",
  zeroLocationsMapped: "Zero Locations Mapped..",
  solIdError: "Please get the SOL ID Mapped against Location for this producer"
}
